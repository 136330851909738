import React, { useEffect, useState } from "react";
import {Button,Col,Container,Form,Modal,Row,Tab,Tabs} from "react-bootstrap";
import Select from "react-select";

function MarketPlaceChnageTab({tabType, options, categories, selectedCategory, setSelectedCategory, handleCategoryChange, searchKey, setSearchKey, handleSearchClick , handleChangeSearch, handleOnKeyEnter}) {
  

  return (
    <Row>
      <Col lg={6}>
        <div className="search-brand">
          <Form.Control
            type="text"
            placeholder="Search Brands / Creator..."
            className=" mr-sm-2"
            value={searchKey}
            onChange={handleChangeSearch}
            onKeyDown={handleOnKeyEnter}
          />
          <svg
          onClick={handleSearchClick}
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M9.58268 17.4993C13.9549 17.4993 17.4993 13.9549 17.4993 9.58268C17.4993 5.21043 13.9549 1.66602 9.58268 1.66602C5.21043 1.66602 1.66602 5.21043 1.66602 9.58268C1.66602 13.9549 5.21043 17.4993 9.58268 17.4993Z"
              stroke="#111111"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M18.3327 18.3327L16.666 16.666"
              stroke="#111111"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </Col>
      <Col md={6}>
        <div className="marketplace-filter-right main-right">
          {/* <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Select options={options} placeholder="Sort By" />
          </Form.Group> */}
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Select value={categories.find((opt) => opt.value === selectedCategory)} onChange={(e)=>{handleCategoryChange(e.value)}} options={categories} placeholder="Categories" />
          </Form.Group>
        </div>
      </Col>
    </Row>
  );
}

export default MarketPlaceChnageTab;
