import "./App.css";
import Routing from "./routing/Routing";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/Style.css";
import { apiService } from "./services/apiservices";
import { useContext, useEffect } from "react";
import { globalContext } from "./GlobalState/globalContext";
import Swal from "sweetalert2";

function App() {
  const globalStates = useContext(globalContext);
  const token = localStorage.getItem("token");
  // const navigate = useNavigate()
  const getUser = async () => {
    try {
      if (token) {
        const response = await apiService.getProfileData();
        globalStates.setLoggedInUser(response.data.user);
        globalStates.setIsLoggedIn(true);
      }
    } catch (error) {
      console.log(error);
      // globalStates.setIsLoggedIn(false)
      // localStorage.clear()
      if (
        error.response.status === 401 &&
        error.response.data.message == "Your session has expired, Please login again."
      ) {
        Swal.fire({
          icon: "error",
          title: "Your Session has expired",
          text: "Login again",
        }).then((result)=>{
          if(result.dismiss == "backdrop" || result.isConfirmed == true){
            console.log("------>",result)
          window.location = "/login"
          globalStates.setIsLoggedIn(false)
          localStorage.clear()
          }
        })
      }
    }
  };


  useEffect(() => {
    if (!token) {
      globalStates.setIsLoggedIn(false);
    } else if (token) {
      getUser();
    }
  }, [localStorage.getItem("token"),globalStates.fullRerender]);
  return <Routing />;
}

export default App;
