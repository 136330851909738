import React, { useRef, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import Swal from "sweetalert2";
import { validateRegisterForm } from "../services/validationService";
import { apiService } from "../services/apiservices";
import Multiselect from "multiselect-react-dropdown";
import { useNavigate } from "react-router-dom";

function RegisterForm({ roleType, setShowLoader }) {
  const workMonthlyOptions = Array.from({ length: 11 }, (_, i) => i);
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  });
  const [profileImage, setProfileImage] = useState("");
  const [creatorData, setCreatorData] = useState({
    image: "",
    firstName: "",
    lastName: "",
    brandName: "",
    websiteLinks: "",
    email: "",
    phoneNumber: "",
    password: "",
    confirmPassword: "",
    description:"",
    role: roleType,
    preferredNiches: [],
    socialLinks: [""],
    mostActivePlatform: "",
    tikTokShopAffiliate: "no",
    workMonthly: 0,
    count_creator_looking: 0,
    affiliate_setup: "no",
    isWeb: true,
  });
  const [errors, setErrors] = useState({
    socialLinks: [],
  });
  const [options, setOptions] = useState([
    { name: "Home Supplies", id: 1 },
    { name: "Kitchenware", id: 2 },
    { name: "Household Appliances", id: 3 },
    { name: "Womenswears", id: 4 },
    { name: "Menswear", id: 5 },
    { name: "Beauty & Personal Care", id: 6 },
    { name: "Electronics", id: 7 },
    { name: "Pet Supplies", id: 8 },
    { name: "Sports & Outdoors", id: 9 },
    { name: "Toys & Hobbies", id: 10 },
    { name: "Furniture", id: 11 },
    { name: "Health", id: 12 },
    { name: "Automotive", id: 13 },
    { name: "Fashion", id: 14 },
    { name: "Bags", id: 15 },
    { name: "Jewelry", id: 16 },
    { name: "Food & Beverage", id: 17 },
  ]);
  const [addSocialLinkError, setAddSocialLinkError] = useState("");
  const imageRef = useRef();
  const lastSocialInputRef = useRef(null);
  const navigate = useNavigate()

  const handleChangeSocialLink = (index, value) => {
    const trimmedValue = value.trimStart();
 
    const updatedLinks = [...creatorData.socialLinks];
    updatedLinks[index] = trimmedValue;
    setCreatorData({ ...creatorData, socialLinks: updatedLinks });
  
    const updatedErrors = [...errors.socialLinks];
    const urlRegex = /^(https?:\/\/)?([\w-]+(\.[\w-]+)+)\/?.*$/;
  
    setAddSocialLinkError("");
    if (trimmedValue && !urlRegex.test(trimmedValue)) {
      updatedErrors[index] = "Please enter a valid URL.";
    } else {
      updatedErrors[index] = "";
    }
  
    setErrors({ ...errors, socialLinks: updatedErrors });
  };
  

  const addSocialLink = () => {
    const lastSocialField = creatorData.socialLinks.length - 1;
    if (creatorData.socialLinks[lastSocialField]) {
      setCreatorData({
        ...creatorData,
        socialLinks: [...creatorData.socialLinks, ""],
      });
      setErrors({ ...errors, socialLinks: [...errors.socialLinks, ""] });
    } else if (!creatorData.socialLinks[lastSocialField]) {
      lastSocialInputRef.current?.focus();
      setAddSocialLinkError(
        "Please fill the empty social link field before adding a new field"
      );
    }
  };

  const removeSocialLink = (index) => {
    const updatedSocialLinks = [...creatorData.socialLinks];
    updatedSocialLinks.splice(index, 1);

    const updatedErrors = { ...errors };
    updatedErrors.socialLinks.splice(index, 1);

    setCreatorData({ ...creatorData, socialLinks: updatedSocialLinks });
    setErrors(updatedErrors);
    setAddSocialLinkError("");
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
  

    const trimmedValue = value.trimStart();
  
    if (name === "phoneNumber") {
      if (/^\d*$/.test(trimmedValue)) { 
        setCreatorData((prev) => ({ ...prev, [name]: trimmedValue }));
        const error = validateRegisterForm(name, trimmedValue, roleType, creatorData);
        setErrors((prev) => ({ ...prev, [name]: error }));
      }
    } else {
      setCreatorData((prev) => ({ ...prev, [name]: trimmedValue })); 
      const error = validateRegisterForm(name, trimmedValue, roleType, creatorData);
      setErrors((prev) => ({ ...prev, [name]: error }));
    }
  };
  

  const handleFileChange = (event) => {
    const { name, files } = event.target;
    const file = files[0];
    console.log(file);

    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setProfileImage(imageUrl);
      setCreatorData((prev) => ({ ...prev, [name]: file }));
      const error = validateRegisterForm(name, file, roleType, creatorData);
      setErrors((prev) => ({ ...prev, [name]: error }));
    }

  };


  const handleMultiSelectChange = (selectedList, selectedItem) => {
    setCreatorData({ ...creatorData, preferredNiches: selectedList });
    const error = validateRegisterForm(
      "preferredNiches",
      selectedList,
      roleType,
      creatorData
    );
    setErrors({ ...errors, preferredNiches: error });
  };

  const onRemove = (selectedList, removedItem) => {
    setCreatorData({ ...creatorData, preferredNiches: selectedList });
    const error = validateRegisterForm(
      "preferredNiches",
      selectedList,
      roleType,
      creatorData
    );
    setErrors({ ...errors, preferredNiches: error });
  };

  const handleSubmit = async (e) => {
    console.log("submission started", creatorData);
    e.preventDefault();
    const newErrors = {};
    Object.keys(creatorData).forEach((key) => {
      newErrors[key] = validateRegisterForm(
        key,
        creatorData[key],
        roleType,
        creatorData
      );
    });

    setErrors(newErrors);
    console.log(errors);

    if (Object.values(newErrors).every((error) => error === "")) {
      setShowLoader(true);
      const form = new FormData();
      form.append("image", creatorData.image);
      if (roleType == "creator") {
        form.append("firstName", creatorData.firstName);
        form.append("lastName", creatorData.lastName);
      } else if (roleType == "brand") {
        form.append("brandName", creatorData.brandName);
        form.append("websiteLinks", creatorData.websiteLinks);
        
      }
      form.append("description",creatorData.description)
      form.append("count_creator_looking", creatorData.count_creator_looking);
      form.append("affiliate_setup", creatorData.affiliate_setup);
      form.append("email", creatorData.email);
      form.append("phoneNumber", creatorData.phoneNumber);
      form.append("password", creatorData.password);
      form.append("activePlatform", creatorData.mostActivePlatform);
      creatorData.preferredNiches.map((category, index) => {
        form.append("preferredNiches", category.name);
      });
      let socialLinkFields = 0
      creatorData.socialLinks.map((link, index) => {
        if (link.trim() !== "") {
          form.append("socialLinks", link);
          socialLinkFields = socialLinkFields + 1
        }
      });
      if(socialLinkFields === 0){
        form.append("socialLinks", [""]);
      }
      form.append(
        "isActiveTikTokAffiliate",
        creatorData.tikTokShopAffiliate == "yes" ? true : false
      );
      form.append("monthlyBrandGoal", creatorData.workMonthly);
      form.append("role", creatorData.role);
      form.append("isWeb", creatorData.isWeb);
      try {
        // for (let [key, value] of form.entries()) {
        //   console.log(key, value); // Logs each key-value pair in the FormData
        // }
        const response = await apiService.userRegister(form);
        // const response = { status: 201 };
        console.log("response", response);

        if (response.status >= 200 && response.status <= 299) {
          setCreatorData({
            ...creatorData,
            image: "",
            firstName: "",
            lastName: "",
            brandName: "",
            websiteLinks: "",
            email: "",
            phoneNumber: "",
            password: "",
            confirmPassword: "",
            description:"",
            preferredNiches: [],
            role: roleType,
            socialLinks: [""],
            mostActivePlatform: "",
            tikTokShopAffiliate: "no",
            workMonthly: 0,
            affiliate_setup: "no",
            count_creator_looking: 0,
            isWeb: true,
          });
          console.log(response?.message)

          setProfileImage("");
          imageRef.current.value = "";
          Swal.fire({
            icon: "success",
            title: "Registration Successful",
            text: response?.data?.message || "You have successfully registered!",
          });
          setShowLoader(false);
          navigate("/")
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text:
            error?.response?.data?.message ||
            "Something went wrong, please try again.",
        });
        setShowLoader(false);
      }
    }
  };
  return (
    <div className="right-side-form register-form">
      <h2 className="register-text">Register Now!</h2>
      <Form>
       <div className="scroll-div-form">
        {roleType == "creator" ? (
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3" >
                <Form.Label htmlFor="firstName" >First name</Form.Label>
                <Form.Control
                  type="text"
                  id="firstName"
                  placeholder="First Name"
                  name="firstName"
                  value={creatorData.firstName}
                  onChange={handleInputChange}
                />
                {errors.firstName && (
                  <span className="text-danger">{errors.firstName}</span>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" >
                <Form.Label htmlFor="lastName">Last name</Form.Label>
                <Form.Control
                  type="text"
                  id="lastName"
                  placeholder="Last name"
                  name="lastName"
                  value={creatorData.lastName}
                  onChange={handleInputChange}
                />
                {errors.lastName && (
                  <span className="text-danger">{errors.lastName}</span>
                )}
              </Form.Group>
            </Col>
          </Row>
        ) : (
          <Form.Group className="mb-3" >
            <Form.Label htmlFor="brandName">Brand Name</Form.Label>
            <Form.Control
              type="text"
              id="brandName"
              placeholder="Brand Name"
              name="brandName"
              value={creatorData.brandName}
              onChange={handleInputChange}
            />
            {errors.brandName && (
              <span className="text-danger">{errors.brandName}</span>
            )}
          </Form.Group>
        )}
        <Row>
        <Col lg={6}>
        <Form.Group className="mb-3" >
          <Form.Label htmlFor="email">Email</Form.Label>
          <Form.Control
          id="email"
            type="email"
            placeholder="Email"
            name="email"
            value={creatorData.email}
            onChange={handleInputChange}
          />
          {errors.email && <span className="text-danger">{errors.email}</span>}
        </Form.Group>
        </Col>
        <Col lg={6}>
        <Form.Group className="mb-3" >
          <Form.Label htmlFor="phoneNumber">Phone Number</Form.Label>
          <Form.Control
            type="text"
            id="phoneNumber"
            placeholder="Phone Number"
            name="phoneNumber"
            value={creatorData.phoneNumber}
            onChange={handleInputChange}
          />
          {errors.phoneNumber && (
            <span className="text-danger">{errors.phoneNumber}</span>
          )}
        </Form.Group>
        </Col>
        <Col lg={6}>
        <Form.Group
          className="mb-3 position-relative"
    
        >
          <Form.Label htmlFor="password">Password</Form.Label>
          <Form.Control
            autoComplete="new-password"
            id="password"
            type={showPassword.password ? "text" : "password"}
            placeholder="Enter your password"
            name="password"
            value={creatorData.password}
            onChange={handleInputChange}
          />
          {errors.password && (
            <span className="text-danger">{errors.password}</span>
          )}
          <img
            onClick={() => {
              setShowPassword({
                ...showPassword,
                password: !showPassword.password,
              });
            }}
            src={
              showPassword.password
                ? require("../assets/images/view-eye.svg").default
                : require("../assets/images/eye-open.svg").default
            }
            alt="Logo || Brandfluence"
            className="eye-icon"
          />
        </Form.Group>
        </Col>
        <Col lg={6}>
        <Form.Group
          className="mb-3 position-relative"
        >
          <Form.Label htmlFor="confirmPassword">Confirm password</Form.Label>
          <Form.Control
          id="confirmPassword"
            autoComplete="new-password"
            type={showPassword.confirmPassword ? "text" : "password"}
            placeholder="Confirm your password"
            name="confirmPassword"
            value={creatorData.confirmPassword}
            onChange={handleInputChange}
          />
          {errors.confirmPassword && (
            <span className="text-danger">{errors.confirmPassword}</span>
          )}
          <img
            onClick={() => {
              setShowPassword({
                ...showPassword,
                confirmPassword: !showPassword.confirmPassword,
              });
            }}
            src={
              showPassword.confirmPassword
                ? require("../assets/images/view-eye.svg").default
                : require("../assets/images/eye-open.svg").default
            }
            alt="Logo || Brandfluence"
            className="eye-icon"
          />
        </Form.Group>
        </Col>
        </Row>
        {roleType == "brand" && (
          <Form.Group className="mb-3" >
            <Form.Label htmlFor="websiteLinks">Website Links</Form.Label>
            <Form.Control
            id="websiteLinks"
              type="text"
              placeholder="Website links"
              name="websiteLinks"
              value={creatorData.websiteLink}
              onChange={handleInputChange}
            />
            {errors.websiteLinks && (
              <span className="text-danger">{errors.websiteLinks}</span>
            )}
          </Form.Group>
        )}
          <Form.Group className="mb-3" >
            <Form.Label htmlFor="image">Upload Profile</Form.Label>
            <div className="upload-img">
              {/* <img
                src={
                  profileImage && !errors?.image
                    ? profileImage
                    : require("../assets/images/upload-icon.png")
                }
              /> */}
              <Form.Control
              id="image"
                type="file"
                placeholder="Upload Image"
                name="image"
                onChange={handleFileChange}
                ref={imageRef}
              />
            </div>
            {errors.image && (
              <span className="text-danger">{errors.image}</span>
            )}
          </Form.Group>
        <Form.Group className="mb-3" >
          <Form.Label htmlFor="description">Description</Form.Label>
          <Form.Control
          id="description"
            as="textarea"
            placeholder="Description"
            name="description"
            value={creatorData.description}
            onChange={handleInputChange}
            style={{ resize: 'none',height : "70px" }}
          />
          {errors.description && (
            <span className="text-danger">{errors.description}</span>
          )}
        </Form.Group>
        <Form.Group className="mb-1" >
          <Form.Label htmlFor="socialLinksLast">Social Links</Form.Label>
          <div
            className={creatorData.socialLinks.length > 2 ? "" : ""}
          >
            {creatorData.socialLinks.map((link, index) => (
              <div key={index} className="mb-2 trash-area">
                <Form.Control
                  type="text"
                  placeholder={`Social Link ${index + 1}`}
                  value={link}
                  onChange={(e) =>
                    handleChangeSocialLink(index, e.target.value)
                  }
                  id={index === creatorData.socialLinks.length - 1
                    ? "socialLinksLast"
                    : null}
                  ref={
                    index === creatorData.socialLinks.length - 1
                      ? lastSocialInputRef
                      : null
                  }
                />
                {index != 0 ? (
                  <i
                    className="fa fa-trash align-middle"
                    aria-hidden="true"
                    onClick={() => removeSocialLink(index)}
                  ></i>
                ) : (
                  ""
                )}

                {errors.socialLinks[index] && (
                  <span className="text-danger">
                    {errors.socialLinks[index]}
                  </span>
                )}
              </div>
            ))}
            {addSocialLinkError && (
              <span className="text-danger">{addSocialLinkError}</span>
            )}
          </div>
          <Button variant="link" onClick={addSocialLink} className="add-btn">
            + Add More
          </Button>
        </Form.Group>
        <Form.Group
          className="mb-3 position-relative"
          
        >
          <Form.Label htmlFor="category">Category</Form.Label>
          <Multiselect
          
            id="category"
            className="multiselector-area"
            placeholder={
              creatorData.preferredNiches.length >= 1 ? "" : "Select"
            }
            options={options}
            selectedValues={creatorData.preferredNiches}
            onSelect={handleMultiSelectChange}
            onRemove={onRemove}
            displayValue="name"
            
          />
          <img
            src={require("../assets/images/dropdown.svg").default}
            alt="Logo || Brandfluence"
            className="arrow-icon"
          />
          {errors.preferredNiches && (
            <span className="text-danger">{errors.preferredNiches}</span>
          )}
        </Form.Group>
        <Form.Group className="mb-3" >
          <Form.Label htmlFor="mostActivePlatform">Most active platform</Form.Label>
          <Form.Control
            type="text"
            id="mostActivePlatform"
            placeholder="Most active platform"
            name="mostActivePlatform"
            value={creatorData.mostActivePlatform}
            onChange={handleInputChange}
          />
          {errors.mostActivePlatform && (
            <span className="text-danger">{errors.mostActivePlatform}</span>
          )}
        </Form.Group>
        <Form.Group
          className="mb-3 position-relative"
          
        >
          <Form.Label htmlFor="tikTokShopAffiliate">
            Are you actively posting videos as an TikTok shop affiliate?{" "}
          </Form.Label>
          <Form.Select
          id="tikTokShopAffiliate"
            name="tikTokShopAffiliate"
            value={creatorData.tikTokShopAffiliate}
            onChange={handleInputChange}
          >
            <option value={"yes"}>Yes</option>
            <option value={"no"}>No</option>
          </Form.Select>
          <img
            src={require("../assets/images/dropdown.svg").default}
            alt="Logo || Brandfluence"
            className="arrow-icon"
          />
        </Form.Group>
        <Form.Group
          className="mb-3 position-relative"
          
        >
          <Form.Label htmlFor="workMonthly">
            How many brands are you looking to work with monthly?
          </Form.Label>
          <Form.Select
          id="workMonthly"
            name="workMonthly"
            value={creatorData.workMonthly}
            onChange={handleInputChange}
          >
            {workMonthlyOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </Form.Select>
          <img
            src={require("../assets/images/dropdown.svg").default}
            alt="Logo || Brandfluence"
            className="arrow-icon"
          />
        </Form.Group>
        {roleType == "brand" && (
          <Form.Group
            className="mb-3 position-relative"
            
          >
            <Form.Label htmlFor="count_creator_looking">
              How many affiliates / creators are you looking to get
            </Form.Label>
            <Form.Select
            id="count_creator_looking"
              name="count_creator_looking"
              value={creatorData.count_creator_looking}
              onChange={handleInputChange}
            >
              {workMonthlyOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Form.Select>
            <img
              src={require("../assets/images/dropdown.svg").default}
              alt="Logo || Brandfluence"
              className="arrow-icon"
            />
          </Form.Group>
        )}
        {roleType == "brand" && (
          <Form.Group
            className="mb-3 position-relative"
            
          >
            <Form.Label htmlFor="affiliate_setup">
              Do you have a affiliate program setup on Instagram or TikTok ?
            </Form.Label>
            <Form.Select
            id="affiliate_setup"
              name="affiliate_setup"
              value={creatorData.affiliate_setup}
              onChange={handleInputChange}
            >
              <option value={"yes"}>Yes</option>
              <option value={"no"}>No</option>
            </Form.Select>
            <img
              src={require("../assets/images/dropdown.svg").default}
              alt="Logo || Brandfluence"
              className="arrow-icon"
            />
          </Form.Group>
        )}
      </div>
        <Button variant="primary" onClick={handleSubmit} className="submit-btn">
          Submit
        </Button>
      </Form>
    </div>
  );
}

export default RegisterForm;
