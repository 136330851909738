export const validateRegisterForm = (name, value, roleType, data) => {
  let error = "";

  switch (name) {
    case "image":
      if (!value) {
        error = "Profile image is required.";
      }
      if (value?.size > "500000") {
        error = "Image size must be less than 500kb";
      }
      const validImageTypes = ["image/jpeg", "image/png", "image/webp"];
      if(value){
        if (!validImageTypes.includes(value?.type)) {
          error = "Only image files (JPEG, PNG, WEBP) are allowed.";
        }
      }
      
      break;
    case "firstName":
    case "lastName":
      if (!value.trim()) {
        error = `${
          name === "firstName" ? "First name" : "Last name"
        } is required.`;
      } else if (!/^[A-Za-z\s]+$/.test(value)) {
        error = `${
          name === "firstName" ? "First name" : "Last name"
        } must contain only letters and spaces.`;
      } else if (value.length < 2 || value.length > 50) {
        error = `${
          name === "firstName" ? "First name" : "Last name"
        } must be between 2 and 50 characters.`;
      }
      if (roleType == "brand") {
        error = "";
      }
      break;
    case "brandName":
      if (!value.trim()) {
        error = `Brand name is required`;
      } else if (value.length < 2 || value.length > 50) {
        error = `Brand name must be between 2 and 50 characters.`;
      }
      if (roleType == "creator") {
        error = "";
      }
      break;
    case "email":
      if (!value.trim()) {
        error = "Email is required.";
      } else if (
        !/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/.test(value)
      ) {
        error = "Invalid email.";
      }
      break;
    case "phoneNumber":
      if (value && !/^\d{10,13}$/.test(value)) {
        error = "Phone number must be between 10 to 13 digits.";
      }
      break;
    case "password":
      const trimmedValue = value.trim();
      if (!trimmedValue) {
        error = "Password is required.";
      } else if (trimmedValue.length < 8) {
        error = "Password must be at least 8 characters long.";
      } else if (
        !/^(?=.*[A-Za-z])(?=.*\d)(?=.*[`~!@#$%^&*()\-_+={[}\|:;"'<,>.?/]).*$/.test(
          trimmedValue
        )
      ) {
        error =
          "Password must include at least 1 letter, 1 number, and 1 special character.";
      }
      break;
    case "confirmPassword":
      const trimmedpass = value.trim();
      console.log("v");
      if (trimmedpass !== data.password) {
        error = "Confirm password does not match the password";
      }
      break;
      case "description":
      if (!value.trim()) {
        error =  `Description is required.`;
      } 
      else if (value.length < 5 && value.length > 500) {
        error = `Description must be between 5 and 500 characters.`;
      }
      break;
    case "websiteLinks":
      const urlRegex = /^(https?:\/\/)?([\w-]+(\.[\w-]+)+)\/?.*$/;
      if (!value.trim()) {
        error = "Website links is required.";
      } else if (value && !urlRegex.test(value)) {
        error = "Please enter a valid URL.";
      }
      if (roleType == "creator") {
        error = "";
      }
      break;
    case "mostActivePlatform":
      if (!value.trim()) {
        error = "Most active platform is required.";
      } else if (value.length < 3 || value.length > 50) {
        error = "Most active platform must be between 3 and 50 characters.";
      }
      break;
    case "preferredNiches":
      if (!value || value.length < 1) {
        error = "Please select at least one category..";
      }
      break;
    default:
      break;
  }
  return error;
};

export const validateUpdateForm = (name, value, roleType) => {
  let error = "";

  switch (name) {
    case "image":
      const urlRegexChexk = /^(https?:\/\/)?([\w-]+(\.[\w-]+)+)\/?.*$/
      if (!value) {
        error = "Profile image is required.";
      }
      if (value?.size > "500000") {
        error = "Image size must be less than 500kb";
      }
      const validImageTypes = ["image/jpeg", "image/png", "image/webp"];
      if(value && !urlRegexChexk.test(value)){
        console.log(value)
        if (!validImageTypes.includes(value?.type)) {
          error = "Only image files (JPEG, PNG, WEBP) are allowed.";
        }
      }
      break;
    case "firstName":
    case "lastName":
      if (!value.trim()) {
        error = `${
          name === "firstName" ? "First name" : "Last name"
        } is required.`;
      } else if (!/^[A-Za-z\s]+$/.test(value)) {
        error = `${
          name === "firstName" ? "First name" : "Last name"
        } must contain only letters and spaces.`;
      } else if (value.length < 2 || value.length > 50) {
        error = `${
          name === "firstName" ? "First name" : "Last name"
        } must be between 2 and 50 characters.`;
      }
      if (roleType == "brand") {
        error = "";
      }
      break;
    case "brandName":
      if (!value.trim()) {
        error = `Brand name is required`;
      } else if (value.length < 2 || value.length > 50) {
        error = `Brand name must be between 2 and 50 characters.`;
      }
      if (roleType == "creator") {
        error = "";
      }
      break;
    case "email":
      if (!value.trim()) {
        error = "Email is required.";
      } else if (
        !/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/.test(value)
      ) {
        error = "Invalid email format.";
      }
      break;
    case "phoneNumber":
      if (value && !/^\d{10,13}$/.test(value)) {
        error = "Phone number must be between 10 to 13 digits.";
      }
      break;
      case "description":
      if (!value.trim()) {
        error =  `Description is required.`;
      } 
      else if (value.length < 5 && value.length > 500) {
        error = `Description must be between 5 and 500 characters.`;
      }
      break;
    case "websiteLinks":
      const urlRegex = /^(https?:\/\/)?([\w-]+(\.[\w-]+)+)\/?.*$/;
      if (!value.trim()) {
        error = "Website links is required.";
      } else if (value && !urlRegex.test(value)) {
        error = "Please enter a valid URL.";
      }
      if (roleType == "creator") {
        error = "";
      }
      break;
    case "mostActivePlatform":
      if (!value.trim()) {
        error = "Most active platform is required.";
      } else if (value.length < 3 || value.length > 50) {
        error = "Most active platform must be between 3 and 50 characters.";
      }
      break;
    case "preferredNiches":
      if (!value || value.length < 1) {
        error = "Please select at least one category..";
      }
      break;
    default:
      break;
  }

  return error;
};
