import { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, replace, useNavigate } from "react-router-dom";
import { globalContext } from "../GlobalState/globalContext";

const Footer = () => {
  const navigate = useNavigate()
  const token = localStorage.getItem("token")
  const globalStates = useContext(globalContext)
  const handleBrandsRedirection = () => {
    if(token){
      navigate('/marketplace')
    }else if(!token){
      navigate('/');
      globalStates.setScrollToBrandSectionInHome(prev=>prev+1)
    }
  }
  return <>
    {/* footer */}
    <footer>
      <Container>
        <Row className="align-items-center">
          <Col md={3}>
            <div className="footer-logo">
              <img src={require('../assets/images/footer-logo.png')} alt="Left image || Brandfluence" className="" />
            </div>
          </Col>
          <Col md={6}>
            <div className="footer-content text-center">
              {/* <a href="#">About</a> */}
              <a style={{color:"white"}} onClick={handleBrandsRedirection}>Brands</a>
              {/* <a href="#">Categories</a> */}
              <Link to={"/contact-us"}>Contact Us</Link>
            </div>
          </Col>
          <Col md={3}>
            <div className="footer-content social-media">
              <a target="_blank" href="https://www.instagram.com/brandfluences/?igsh=bW53ZnRuenV1dDZv">
                <img src={require('../assets/images/insta-icon-.svg').default} alt="Left image || Brandfluence" className="" />
              </a>
              <a target="_blank" href="https://www.tiktok.com/@brandfluences?_t=8sMzGYTJDUN&_r=1">
              <img src={require('../assets/images/tiktok-icon.svg').default} alt="Left image || Brandfluence" className="" />
              </a>
              <a target="_blank" href="https://www.linkedin.com/company/brandfluence-group/">
              <img src={require('../assets/images/linkdin.svg').default} alt="Left image || Brandfluence" className="" />
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  </>;
};
export default Footer;
