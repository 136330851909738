import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import Swal from "sweetalert2";
import { apiService } from "../services/apiservices";
import Multiselect from "multiselect-react-dropdown";
import { config } from "../config/config";
import { useNavigate } from "react-router-dom";
import { globalContext } from "../GlobalState/globalContext";
import { validateUpdateForm } from "../services/validationService";
import Loader from "../common/Loader";

function UpdateProfilePage() {
  const [showLoader, setShowLoader] = useState(false);
  const workMonthlyOptions = Array.from({ length: 11 }, (_, i) => i);
  const [profileImage, setProfileImage] = useState("");
  const [roleType, setRoleType] = useState();
  const globalStates = useContext(globalContext);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const lastSocialInputRef = useRef(null);
  const [addSocialLinkError, setAddSocialLinkError] = useState("");

  useEffect(() => {
    if (!token) {
      navigate("/login",{replace:true});
    }
  }, [globalStates.loggedInUser]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (globalStates.loggedInUser?.role) {
      setRoleType(globalStates.loggedInUser.role);
    }
  }, [globalStates.loggedInUser]);

  const imageRef = useRef();
  const [creatorData, setCreatorData] = useState({
    image: "",
    firstName: "",
    lastName: "",
    brandName: "",
    websiteLinks: "",
    email: "",
    phoneNumber: "",
    password: "",
    description : "",
    role: roleType,
    preferredNiches: [],
    socialLinks: [""],
    mostActivePlatform: "",
    tikTokShopAffiliate: "no",
    count_creator_looking: 0,
    affiliate_setup: "no",
    workMonthly: 0,
    isWeb: true,
  });
  const [errors, setErrors] = useState({
    socialLinks: [],
  });
  const [options, setOptions] = useState([
    { name: "Home Supplies", id: 1 },
    { name: "Kitchenware", id: 2 },
    { name: "Household Appliances", id: 3 },
    { name: "Womenswears", id: 4 },
    { name: "Menswear", id: 5 },
    { name: "Beauty & Personal Care", id: 6 },
    { name: "Electronics", id: 7 },
    { name: "Pet Supplies", id: 8 },
    { name: "Sports & Outdoors", id: 9 },
    { name: "Toys & Hobbies", id: 10 },
    { name: "Furniture", id: 11 },
    { name: "Health", id: 12 },
    { name: "Automotive", id: 13 },
    { name: "Fashion", id: 14 },
    { name: "Bags", id: 15 },
    { name: "Jewelry", id: 16 },
    { name: "Food & Beverage", id: 17 },
  ]);

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, []);

  const showCategory = (data, allOptions) => {
    if (data) {
      let categories;
      if (data.length == 1) {
        categories = data[0].split(",");
      } else {
        categories = data;
      }
      const preSelectedCtaegories = allOptions.filter((cat, index) => {
        if (categories.includes(cat.name)) {
          return cat;
        }
      });
      return preSelectedCtaegories;
    }
  };

  useEffect(() => {
    if (globalStates.loggedInUser) {
      setCreatorData({
        ...creatorData,
        image: `${config.imageUrl}${globalStates.loggedInUser?.image}` || "",
        firstName: globalStates.loggedInUser?.firstName || "",
        lastName: globalStates.loggedInUser?.lastName || "",
        brandName: globalStates.loggedInUser?.brandName || "",
        websiteLinks: globalStates.loggedInUser?.websiteLinks || "",
        email: globalStates.loggedInUser?.email || "",
        description : globalStates.loggedInUser?.description || "",
        phoneNumber: globalStates.loggedInUser?.phoneNumber || "",
        role: globalStates.loggedInUser?.role || "",
        preferredNiches:
          showCategory(globalStates.loggedInUser?.preferredNiches, options) ||
          [],
        socialLinks: globalStates.loggedInUser?.socialLinks.length > 0 ? globalStates.loggedInUser?.socialLinks : [""],
        mostActivePlatform: globalStates.loggedInUser?.activePlatform || "",
        tikTokShopAffiliate:
          globalStates.loggedInUser?.tikTokShopAffiliate || "no",
        workMonthly: globalStates.loggedInUser?.monthlyBrandGoal || 0,
        isWeb: globalStates.loggedInUser?.isWeb || true,
        count_creator_looking:
          globalStates.loggedInUser?.count_creator_looking || 0,
        affiliate_setup: globalStates.loggedInUser?.affiliate_setup || "no",
      });
    }
    if (globalStates.loggedInUser?.image) {
      setProfileImage(`${config.imageUrl}${globalStates.loggedInUser.image}`);
    }
  }, [globalStates.isLoggedIn, globalStates.loggedInUser]);

  const handleChangeSocialLink = (index, value) => {
    const updatedLinks = [...creatorData.socialLinks];
    updatedLinks[index] = value;
    setCreatorData({ ...creatorData, socialLinks: updatedLinks });
    setAddSocialLinkError("");

    const updatedErrors = [...errors.socialLinks];
    const urlRegex = /^(https?:\/\/)?([\w-]+(\.[\w-]+)+)\/?.*$/;
    if (value && !urlRegex.test(value)) {
      updatedErrors[index] = "Please enter a valid URL.";
    } else {
      updatedErrors[index] = "";
    }
    setErrors({ ...errors, socialLinks: updatedErrors });
  };

  const addSocialLink = () => {
    const lastSocialField = creatorData.socialLinks.length - 1;
    if (creatorData.socialLinks[lastSocialField]) {
      setCreatorData({
        ...creatorData,
        socialLinks: [...creatorData.socialLinks, ""],
      });
      setErrors({ ...errors, socialLinks: [...errors.socialLinks, ""] });
    } else if (!creatorData.socialLinks[lastSocialField]) {
      lastSocialInputRef.current?.focus();
      setAddSocialLinkError(
        "Please fill the empty social link field before adding a new field"
      );
    }
  };

  const removeSocialLink = (index) => {
    console.log(creatorData.socialLinks[index]);
    if (!creatorData.socialLinks[index]) {
      const updatedSocialLinks = [...creatorData.socialLinks];
      updatedSocialLinks.splice(index, 1);

      const updatedErrors = { ...errors };
      updatedErrors.socialLinks.splice(index, 1);

      setCreatorData({ ...creatorData, socialLinks: updatedSocialLinks });
      setErrors(updatedErrors);
    } else {
      Swal.fire({
        title: "Are you sure?",
        text: "You want to delete this link.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete!",
      }).then((result) => {
        if (result.isConfirmed) {
          if (index === 0) {
            const links = [...creatorData.socialLinks];
            links[0] = "";
            console.log(links)
            setCreatorData({ ...creatorData, socialLinks: links });
          } else {
            const updatedSocialLinks = [...creatorData.socialLinks];
            updatedSocialLinks.splice(index, 1);

            const updatedErrors = { ...errors };
            updatedErrors.socialLinks.splice(index, 1);

            setCreatorData({ ...creatorData, socialLinks: updatedSocialLinks });
            setErrors(updatedErrors);
          }
        }
      });
    }

    setAddSocialLinkError("");
  };

  const handleInputChange = (event) => {
      const { name, value } = event.target;
    
  
      const trimmedValue = value.trimStart();
    
      if (name === "phoneNumber") {
        if (/^\d*$/.test(trimmedValue)) { 
          setCreatorData((prev) => ({ ...prev, [name]: trimmedValue }));
          const error = validateUpdateForm(name, trimmedValue, roleType, creatorData);
          setErrors((prev) => ({ ...prev, [name]: error }));
        }
      } else {
        setCreatorData((prev) => ({ ...prev, [name]: trimmedValue })); 
        const error = validateUpdateForm(name, trimmedValue, roleType, creatorData);
        setErrors((prev) => ({ ...prev, [name]: error }));
      }
    };

  const handleFileChange = (event) => {
    const { name, files } = event.target;
    const file = files[0];

    if (file) {
      const imageUrl = URL.createObjectURL(file);
      
      const error = validateUpdateForm(name, file, roleType);
      if(!error){
        setProfileImage(imageUrl);
        setCreatorData((prev) => ({ ...prev, [name]: file }));
      }

    setErrors((prev) => ({ ...prev, [name]: error }));
    }

    
  };

  const handleMultiSelectChange = (selectedList, selectedItem) => {
    setCreatorData({ ...creatorData, preferredNiches: selectedList });
    const error = validateUpdateForm("preferredNiches", selectedList, roleType);
    setErrors({ ...errors, preferredNiches: error });
  };

  const onRemove = (selectedList, removedItem) => {
    setCreatorData({ ...creatorData, preferredNiches: selectedList });
    const error = validateUpdateForm("preferredNiches", selectedList, roleType);
    setErrors({ ...errors, preferredNiches: error });
  };

  const handleSubmit = async (e) => {
    console.log("submission started", creatorData);
    e.preventDefault();
    const newErrors = {};
    Object.keys(creatorData).forEach((key) => {
      newErrors[key] = validateUpdateForm(key, creatorData[key], roleType);
    });

    setErrors(newErrors);
    console.log(errors);

    if (Object.values(newErrors).every((error) => error === "")) {
      setShowLoader(true);
      const form = new FormData();
      form.append("image", creatorData.image);
      if (roleType == "creator") {
        form.append("firstName", creatorData.firstName);
        form.append("lastName", creatorData.lastName);
      } else if (roleType == "brand") {
        form.append("brandName", creatorData.brandName);
        form.append("websiteLinks", creatorData.websiteLinks);
        form.append("count_creator_looking", creatorData.count_creator_looking);
        form.append("affiliate_setup", creatorData.affiliate_setup);
      }
      form.append("description",creatorData.description)
      form.append("email", creatorData.email);
      form.append("phoneNumber", creatorData.phoneNumber);
      form.append("activePlatform", creatorData.mostActivePlatform);
      creatorData.preferredNiches.map((category, index) => {
        form.append("preferredNiches", category.name);
      });
      let socialLinkFields = 0
      creatorData.socialLinks.map((link, index) => {
        if (link.trim() !== "") {
          form.append("socialLinks", link);
          socialLinkFields = socialLinkFields + 1
        }
      });
      if(socialLinkFields === 0){
        form.append("socialLinks", [""]);
      }
      form.append(
        "isActiveTikTokAffiliate",
        creatorData.tikTokShopAffiliate == "yes" ? true : false
      );
      form.append("monthlyBrandGoal", creatorData.workMonthly);
      form.append("role", creatorData.role);
      form.append("isWeb", creatorData.isWeb);
      try {
        // for (let [key, value] of form.entries()) {
        //   console.log(key, value); // Logs each key-value pair in the FormData
        // }
        const response = await apiService.updateUserProfile(form);
        // const response = { status: 201 };
        console.log(creatorData.preferredNiches);
        console.log("response", response);

        if (response.status >= 200 && response.status <= 299) {
          Swal.fire({
            icon: "success",
            title:  response?.data?.message || "Your profile has been updated successfully",
            // text:
            //   response?.message ||
            //   "Your profile has been updated successfully",
          });
          navigate("/creator-profile");
          setShowLoader(false);
          globalStates.setFullRerender((prev) => prev + 1)
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text:
            error?.response?.data?.message ||
            "Something went wrong, please try again.",
        });
        setShowLoader(false);
      }
    }
  };
  return (
    <>
    <Loader showLoader={showLoader}/>
      {globalStates.loggedInUser && (
        <section
          className="right-side-form edit-profile-page"
        >
          <Container>
          <h2 className="register-text mt-5 d-flex justify-content-center text-light">
            Edit Profile
          </h2>
          <Row className="justify-content-center">
            <Col lg={9}>
            <div className="main-form-edit">
              <Form>
                <Row>
                  <Form.Group
                    className="mb-3 d-flex flex-column align-items-center"
                    
                  >
                    {/* <Form.Label htmlFor="image">Edit Profile</Form.Label> */}
                    <div className="upload-img main">
                      <img
                        src={
                          profileImage
                            ? profileImage
                            : require("../assets/images/upload-icon.png")
                        }
                      />
                      <Form.Control
                      id="image"
                        type="file"
                        placeholder="Upload Image"
                        name="image"
                        onChange={handleFileChange}
                        ref={imageRef}
                      />
                    </div>
                    {errors.image && (
                      <span className="text-danger">{errors.image}</span>
                    )}
                  </Form.Group>
                </Row>
                {roleType == "creator" ? (
                  <Row>
                    <Col md={6}>
                      <Form.Group className="mb-3" >
                        <Form.Label htmlFor="firstName">First name</Form.Label>
                        <Form.Control
                        id="firstName"
                          type="text"
                          placeholder="First Name"
                          name="firstName"
                          value={creatorData.firstName}
                          onChange={handleInputChange}
                        />
                        {errors.firstName && (
                          <span className="text-danger">{errors.firstName}</span>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3" >
                        <Form.Label htmlFor="lastName">Last name</Form.Label>
                        <Form.Control
                          type="text"
                          id="lastName"
                          placeholder="Last name"
                          name="lastName"
                          value={creatorData.lastName}
                          onChange={handleInputChange}
                        />
                        {errors.lastName && (
                          <span className="text-danger">{errors.lastName}</span>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                ) : (
                  <Form.Group className="mb-3" >
                    <Form.Label htmlFor="brandName">Brand Name</Form.Label>
                    <Form.Control
                      type="text"
                      id="brandName"
                      placeholder="Brand Name"
                      name="brandName"
                      value={creatorData.brandName}
                      onChange={handleInputChange}
                    />
                    {errors.brandName && (
                      <span className="text-danger">{errors.brandName}</span>
                    )}
                  </Form.Group>
                )}

                <Form.Group className="mb-3" >
                  <Form.Label htmlFor="email">Email</Form.Label>
                  <Form.Control
                  id="email"
                    type="email"
                    placeholder="Email"
                    name="email"
                    value={creatorData.email}
                    onChange={handleInputChange}
                  />
                  {errors.email && (
                    <span className="text-danger">{errors.email}</span>
                  )}
                </Form.Group>
                <Form.Group className="mb-3" >
                          <Form.Label htmlFor="phoneNumber">Phone Number</Form.Label>
                          <Form.Control
                            type="text"
                            id="phoneNumber"
                            placeholder="Phone Number"
                            name="phoneNumber"
                            value={creatorData.phoneNumber}
                            onChange={handleInputChange}
                          />
                          {errors.phoneNumber && (
                            <span className="text-danger">{errors.phoneNumber}</span>
                          )}
                        </Form.Group>
                {roleType == "brand" && (
                  <Form.Group className="mb-3" >
                    <Form.Label htmlFor="websiteLinks">Website Links</Form.Label>
                    <Form.Control
                      type="text"
                      id="websiteLinks"
                      placeholder="Website links"
                      name="websiteLinks"
                      value={creatorData?.websiteLinks}
                      onChange={handleInputChange}
                    />
                    {errors.websiteLinks && (
                      <span className="text-danger">{errors.websiteLinks}</span>
                    )}
                  </Form.Group>
                )}
                  <Form.Group className="mb-3" >
                    <Form.Label htmlFor="description">Description</Form.Label>
                    <Form.Control
                    id="description"
                      as="textarea"
                      placeholder="Add description"
                      name="description"
                      value={creatorData.description}
                      onChange={handleInputChange}
                      style={{ resize: "none", height: "120px" }}
                    />
                    {errors.description && (
                      <span className="text-danger">{errors.description}</span>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-1" >
                  <Form.Label htmlFor="socialLinksLast">Social Links</Form.Label>
                  <div
                    className={
                      creatorData.socialLinks.length > 2 ? "outer-div" : ""
                    }
                  >
                    {creatorData?.socialLinks &&
                      creatorData.socialLinks.map((link, index) => (
                        <div key={index} className="mb-2 trash-area">
                          <Form.Control
                            type="text"
                            placeholder={
                              creatorData.socialLinks.length > 1
                                ? `Social Link ${index + 1}`
                                : `Add Social Link`
                            }
                            value={link}
                            onChange={(e) =>
                              handleChangeSocialLink(index, e.target.value)
                            }
                            id={index === creatorData.socialLinks.length - 1
                              ? "socialLinksLast"
                              : null}
                            ref={
                              index === creatorData.socialLinks.length - 1
                                ? lastSocialInputRef
                                : null
                            }
                          />
                          {index != 0 && 
                            <i
                              className="fa fa-trash align-middle"
                              aria-hidden="true"
                              onClick={() => removeSocialLink(index)}
                            ></i>
                          }

                          {errors.socialLinks[index] && (
                            <span className="text-danger">
                              {errors.socialLinks[index]}
                            </span>
                          )}
                        </div>
                      ))}
                  </div>
                  {addSocialLinkError && (
                    <span className="text-danger">{addSocialLinkError}</span>
                  )}
                  <Button
                    variant="link"
                    onClick={addSocialLink}
                    className="add-btn"
                  >
                    + Add More
                  </Button>
                </Form.Group>
                <Form.Group
                  className="mb-3 position-relative"
                  
                >
                  <Form.Label htmlFor="preferredNiches">Category</Form.Label>
                  <Multiselect
                  id="preferredNiches"
                    className="multiselector-area"
                    placeholder={
                      creatorData.preferredNiches.length >= 1 ? "" : "Select"
                    }
                    options={options}
                    selectedValues={creatorData.preferredNiches}
                    onSelect={handleMultiSelectChange}
                    onRemove={onRemove}
                    displayValue="name"
                  />
                  <img
                    src={require("../assets/images/dropdown.svg").default}
                    alt="Logo || Brandfluence"
                    className="arrow-icon"
                  />
                  {errors.preferredNiches && (
                    <span className="text-danger">{errors.preferredNiches}</span>
                  )}
                </Form.Group>
                <Form.Group className="mb-3" >
                  <Form.Label htmlFor="mostActivePlatform">Most active platform</Form.Label>
                  <Form.Control
                    type="text"
                    id="mostActivePlatform"
                    placeholder="Most active platform"
                    name="mostActivePlatform"
                    value={creatorData.mostActivePlatform}
                    onChange={handleInputChange}
                  />
                  {errors.mostActivePlatform && (
                    <span className="text-danger">{errors.mostActivePlatform}</span>
                  )}
                </Form.Group>
                <Form.Group
                
                  className="mb-3 position-relative"
                  
                >
                  <Form.Label htmlFor="tikTokShopAffiliate">
                    Are you actively posting videos as an TikTok shop affiliate?{" "}
                  </Form.Label>
                  <Form.Select
                  id="tikTokShopAffiliate"
                    name="tikTokShopAffiliate"
                    value={creatorData.tikTokShopAffiliate}
                    onChange={handleInputChange}
                  >
                    <option value={"yes"}>Yes</option>
                    <option value={"no"}>No</option>
                  </Form.Select>
                  <img
                    src={require("../assets/images/dropdown.svg").default}
                    alt="Logo || Brandfluence"
                    className="arrow-icon"
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3 position-relative"
                  
                >
                  <Form.Label htmlFor="workMonthly">
                    How many brands are you looking to work with monthly?
                  </Form.Label>
                  <Form.Select
                    name="workMonthly"
                    id="workMonthly"
                    value={creatorData.workMonthly}
                    onChange={handleInputChange}
                  >
                    {workMonthlyOptions.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </Form.Select>
                  <img
                    src={require("../assets/images/dropdown.svg").default}
                    alt="Logo || Brandfluence"
                    className="arrow-icon"
                  />
                </Form.Group>
                {roleType == "brand" && (
                  <Form.Group
                    className="mb-3 position-relative"
                    
                  >
                    <Form.Label htmlFor="count_creator_looking">
                      How many affiliates / creators are you looking to get
                    </Form.Label>
                    <Form.Select
                    id="count_creator_looking"
                      name="count_creator_looking"
                      value={creatorData.count_creator_looking}
                      onChange={handleInputChange}
                    >
                      {workMonthlyOptions.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </Form.Select>
                    <img
                      src={require("../assets/images/dropdown.svg").default}
                      alt="Logo || Brandfluence"
                      className="arrow-icon"
                    />
                  </Form.Group>
                )}
                {roleType == "brand" && (
                  <Form.Group
                    className="mb-3 position-relative"
                    
                  >
                    <Form.Label htmlFor="affiliate_setup">
                      Do you have a affiliate program setup on Instagram or TikTok ?
                    </Form.Label>
                    <Form.Select
                    id="affiliate_setup"
                      name="affiliate_setup"
                      value={creatorData.affiliate_setup}
                      onChange={handleInputChange}
                    >
                      <option value={"yes"}>Yes</option>
                      <option value={"no"}>No</option>
                    </Form.Select>
                    <img
                      src={require("../assets/images/dropdown.svg").default}
                      alt="Logo || Brandfluence"
                      workMonthly
                      className="arrow-icon"
                    />
                  </Form.Group>
                )}

                <div className="d-flex justify-content-center">
                  <Button
                    variant="primary"
                    onClick={handleSubmit}
                    className="submit-btn mb-5"
                  >
                    Update
                  </Button>
                </div>
              </Form>
              </div>
            </Col>
          </Row>
          </Container>
        </section>
      )}
    </>
  );
}

export default UpdateProfilePage;
