import React from 'react'
import { useState } from "react"
import { globalContext } from './globalContext'


function GlobalStates({children}) {
    const [loggedInUser, setLoggedInUser] = useState()
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [fullRerender, setFullRerender] = useState(0)
    const [scrollToBrandSectionInHome, setScrollToBrandSectionInHome] = useState(0)

    const states = {
        loggedInUser, setLoggedInUser,
        isLoggedIn, setIsLoggedIn,
        fullRerender, setFullRerender,
        scrollToBrandSectionInHome, setScrollToBrandSectionInHome
      }
  return (
    <globalContext.Provider value={states}>
        {children}
    </globalContext.Provider>
  )
}

export default GlobalStates