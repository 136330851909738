import { Button, Col, Container, Form, Row, Tab, Tabs } from "react-bootstrap";

const Register = () => {
  return (
    <>
      {/* Login page start */}
      <section className="login-section">
        <Container fluid>
          <Row className="align-items-center">
            <Col md={6}>
              <div className="left-side-img register-left-side">
                <img src={require('../assets/images/register-bg.png')} alt="Left image || Brandfluence" className="w-100" />
                {/* <img src={require('../assets/images/register-brand.png')} alt="Left image || Brandfluence" className="w-100" /> */}
              </div>
            </Col>
            <Col md={6}>
              <div className="right-side-form">
                <img src={require('../assets/images/logo-icon.png')} alt="Logo || Brandfluence" className="" />
                <h1>Register</h1>
                <Tabs
                  defaultActiveKey="creator"
                  id="uncontrolled-tab-example"
                  className=""
                >
                  <Tab eventKey="creator" title="As Creator">
                    <Form>
                      <Row>
                        <Col md={6}>
                          <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>First name</Form.Label>
                            <Form.Control type="text" placeholder="First Name" />
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Last name</Form.Label>
                            <Form.Control type="text" placeholder="Last name" />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" placeholder="Email" />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control type="number" placeholder="Phone Number" />
                      </Form.Group>
                      <Form.Group className="mb-3 position-relative" controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" placeholder="Enter your password" />
                        <img src={require('../assets/images/view-eye.svg').default} alt="Logo || Brandfluence" className="eye-icon" />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Social Links</Form.Label>
                        <Form.Control type="text" placeholder="Social links" />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Most active platform</Form.Label>
                        <Form.Control type="text" placeholder="Most active platform" />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Are you actively posting videos as an TikTok shop affiliate? </Form.Label>
                        <Form.Control type="text" placeholder="Yes/No" />
                      </Form.Group>
                      <Form.Group className="mb-3 position-relative" controlId="formBasicEmail">
                        <Form.Label>How many brands are you looking to work with monthly?</Form.Label>
                        <Form.Select>
                          <option>00</option>
                          <option>01</option>
                          <option>02</option>
                          <option>03</option>
                          <option>04</option>
                          <option>05</option>
                          <option>06</option>
                          <option>07</option>
                        </Form.Select>
                        <img src={require('../assets/images/dropdown.svg').default} alt="Logo || Brandfluence" className="eye-icon" />
                      </Form.Group>
                      <Button variant="primary" type="submit" className="submit-btn">
                        Submit
                      </Button>
                    </Form>
                  </Tab>
                  <Tab eventKey="brand" title="As Brand">
                    <Form>
                      <Row>
                        <Col md={6}>
                          <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>First name</Form.Label>
                            <Form.Control type="text" placeholder="First Name" />
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Last name</Form.Label>
                            <Form.Control type="text" placeholder="Last name" />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" placeholder="Email" />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control type="number" placeholder="Phone Number" />
                      </Form.Group>
                      <Form.Group className="mb-3 position-relative" controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" placeholder="Enter your password" />
                        <img src={require('../assets/images/view-eye.svg').default} alt="Logo || Brandfluence" className="eye-icon" />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Social Links</Form.Label>
                        <Form.Control type="text" placeholder="Social links" />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Most active platform</Form.Label>
                        <Form.Control type="text" placeholder="Most active platform" />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Are you actively posting videos as an TikTok shop affiliate? </Form.Label>
                        <Form.Control type="text" placeholder="Yes/No" />
                      </Form.Group>
                      <Form.Group className="mb-3 position-relative" controlId="formBasicEmail">
                        <Form.Label>How many brands are you looking to work with monthly?</Form.Label>
                        <Form.Select>
                          <option>00</option>
                          <option>01</option>
                          <option>02</option>
                          <option>03</option>
                          <option>04</option>
                          <option>05</option>
                          <option>06</option>
                          <option>07</option>
                        </Form.Select>
                        <img src={require('../assets/images/dropdown.svg').default} alt="Logo || Brandfluence" className="eye-icon" />
                      </Form.Group>
                      <Form.Group className="mb-3 position-relative" controlId="formBasicEmail">
                        <Form.Label>How many affiliates / creators are you looking to get</Form.Label>
                        <Form.Select>
                          <option>00</option>
                          <option>01</option>
                          <option>02</option>
                          <option>03</option>
                          <option>04</option>
                          <option>05</option>
                          <option>06</option>
                          <option>07</option>
                        </Form.Select>
                        <img src={require('../assets/images/dropdown.svg').default} alt="Logo || Brandfluence" className="eye-icon" />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Do you have a affiliate program setup on Instagram or TikTok ?</Form.Label>
                        <Form.Select type="text" placeholder="Yes/No" />
                      </Form.Group>
                      <Button variant="primary" type="submit" className="submit-btn">
                        Submit
                      </Button>
                    </Form>
                  </Tab>
                </Tabs>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Login page end */}
    </>
  );
};
export default Register;
