import { Button, Col, Container, Form, Row } from "react-bootstrap";
import RegisterForm from "./RegisterForm";
import Loader from "../common/Loader";
import { useContext, useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { globalContext } from "../GlobalState/globalContext";

const Brands = () => {
    const targetDivRef = useRef(null);
    const location = useLocation();
    const [showLoader, setShowLoader] = useState(false);
    const navigate = useNavigate()
    const globalStates = useContext(globalContext)
    const token = localStorage.getItem("token")
    useEffect(() => {
        if(location?.state?.openRegister){
            targetDivRef.current?.scrollIntoView({ behavior: "smooth" });
        }else{
            window.scrollTo(0, 0);
        }
      }, []);

    useEffect(()=>{
        if(token){
            console.log("hello",token)
            navigate('/')
        }
    },[token])
    return (
        <>
            {/* Creator Hero section start */}
            <Loader showLoader={showLoader}/>
            <section className="creator-bg brand-bg">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={10}>
                            <div className="creator-cnt">
                                <h1>Welcome to Brandfluence:</h1>
                                <h3>Where Brands and Creators Connect</h3>
                            </div>
                        </Col>
                    </Row>
                    {/* <img src={require('../assets/images/asset-2.svg').default} alt="Left image || Brandfluence" className="assets-2" /> */}
                    <img src={require('../assets/images/asset-1.svg').default} alt="Left image || Brandfluence" className="assets-1" />
                </Container>
            </section>
            {/* Creator Hero section end */}

            {/* Community section start */}
            <section className="community-section">
                <Container>
                    <h2>Let us <span className="join-text">help you unlock</span> the full potential of creator-driven <br></br>marketing
                        <span className="join-text-under"> Grow your brand</span> with <span className="join-text">Brandfluence</span> today</h2>
                    <Row>
                        <Col md={6}>
                            <div className="under-cmny">
                                <img src={require('../assets/images/brand-1.png')} alt="Left image || Brandfluence" className="" />
                                <h3>Find <span>your perfect match</span> </h3>
                                <p>We connect you with optimized creators tailored to your niche and category. </p>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="under-cmny">
                                <img src={require('../assets/images/brand-2.png')} alt="Left image || Brandfluence" className="" />
                                <h3>Boost <span>your</span> social content</h3>
                                <p>Elevate your UGC and influencer campaigns with
                                    high-performing, authentic content.</p>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="under-cmny">
                                <img src={require('../assets/images/brand-11.png')} alt="Left image || Brandfluence" className="" />
                                <h3>Drive sales <span>like never before</span> </h3>
                                <p>Watch as creators generate consistent, impactful sales for your products. </p>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="under-cmny">
                                <img src={require('../assets/images/brand-4.png')} alt="Left image || Brandfluence" className="" />
                                <h3>Achieve <span>unmatched</span> growth</h3>
                                <p>Brands partnering with Brandfluence are hitting six-figure sales milestones every month. </p>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="under-cmny">
                                <img src={require('../assets/images/brand-5.png')} alt="Left image || Brandfluence" className="" />
                                <h3>Results <span>you can count on</span> </h3>
                                <p>Our creators are driving 4-5 figures per month in sales for the brands they represent. </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            {/* Community section end */}

            {/* Performace section start */}
            <section className="community-section performance-section">
                <Container>
                    <h2 className="text-center"> <span className="join-text">Brands Performance</span> with <span className="join-text">BrandFluence</span> <br></br>
                        just after 28 days</h2>
                    <Row>
                        <Col md={4}>
                            <div className="performace-inner">
                                <p>Affiliate GMW</p>
                                <h4>$19.1K</h4>
                                <span>80.6% of seller GMW</span>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="performace-inner">
                                <p>Affiliate LIVE GMW</p>
                                <h4>$0</h4>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="performace-inner">
                                <p>Affiliate shoppable video...</p>
                                <h4>$18.8K</h4>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="performace-inner">
                                <p>Affiliate showcase GMW</p>
                                <h4>$259.92</h4>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="performace-inner">
                                <p>Est . Commission</p>
                                <h4>$2.8K</h4>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="performace-inner">
                                <p>Items sold</p>
                                <h4>620</h4>
                            </div>
                        </Col>
                    </Row>
                    <h5>Brands performance with BrandFluence after 60 days</h5>
                    <Row className="justify-content-center">
                        <Col md={4}>
                            <div className="performace-inner">
                                <p>GMV</p>
                                <h4>$104,959.01</h4>
                                <span>+111%</span>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="performace-inner">
                                <p>Buyers</p>
                                <h4>4,730</h4>
                                <span>+143%</span>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="performace-inner">
                                <p>Ordered SKUs</p>
                                <h4>5,852</h4>
                                <span>+117%</span>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="performace-inner">
                                <p>Visitors</p>
                                <h4>330,384</h4>
                                <span>+105%</span>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            {/* Performace section end */}

            {/* Register section start */}
            <section className="login-section creator-register-section" id="brand-registration">
                <Container fluid>
                    <Row className="align-items-center">
                        <Col lg={6}>
                            <div className="left-side-img register-left-side"  ref={targetDivRef}>
                                <img src={require('../assets/images/brand-inner.png')} alt="Left image || Brandfluence" className="w-100" />
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className="brand-registration-form">
                                <RegisterForm roleType={"brand"} setShowLoader={setShowLoader} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            {/* Register section start */}
        </>
    )
};
export default Brands;