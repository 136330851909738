import axios from 'axios';
import { formDataHeader, formDataHeaderToken, jsonHeaders, jsonHeadersToken } from '../helpers';
import { config, localConfig } from '../config/config';


const configFormDataHeaders = () => {
    return {
        headers: formDataHeader()
    }
} 

const formDataToken = () => {
    return {
        headers: formDataHeaderToken()
    }
} 

const configJsonHeaders = () => {
    return {
        headers: jsonHeaders()
    }
} 

const jsonHeadersTokenHeader = () => {
    return {
        headers: jsonHeadersToken()
    }
    
}


export const apiService = {
    userRegister,
    userLogin,
    getMarketplaceData,
    getProfileData,
    updateUserProfile,
    getForgotPasswordEmail,
    resetPasswordOtp
};

function userRegister(params){
    return axios.post(`${config.apiUrl}/user/create-user`, params, configFormDataHeaders())
}

function userLogin(params){
    return axios.post(`${config.apiUrl}/user/login`,params,configJsonHeaders())
}

function getMarketplaceData(role,page = 1,category = "All",search = ""){
    return axios.get(`${config.apiUrl}/user/list?role=${role}&page=${page}&category=${category}&search=${search}`,jsonHeadersTokenHeader())
}

function getProfileData(){
    return axios.get(`${config.apiUrl}/user/user-profile`,jsonHeadersTokenHeader())
}

function updateUserProfile(params){
    return axios.put(`${config.apiUrl}/user/create-user`, params, formDataToken())
}

function getForgotPasswordEmail(params){
    // return axios.post(`${config.apiUrl}/user/forgot-password`,params,configJsonHeaders())
    return axios.post(`${config.apiUrl}/user/forgot-password`,params,configJsonHeaders())
}

function resetPasswordOtp(token,params){
    // return axios.post(`${config.apiUrl}/user/forgot-password`,params,configJsonHeaders())
    return axios.post(`${config.apiUrl}/user/reset-password/${token}`,params,configJsonHeaders())
}