import { useContext, useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import { apiService } from "../services/apiservices";
import MarketPlaceChnageTab from "./MarketPlaceChangeTab";
import MarketPlaceDataCard from "./MarketPlaceDataCard";
import MarketPlaceDataDetailModal from "./MarketPlaceDataDetailModal";
import Pagination from "react-js-pagination";
import { useNavigate } from "react-router-dom";
import Loader from "../common/Loader";
import Swal from "sweetalert2";
import { globalContext } from "../GlobalState/globalContext";

const options = [
  { value: "Diamond", label: "Diamond" },
  { value: "Platinum", label: "Platinum" },
  { value: "Gold", label: "Gold" },
  { value: "Silver", label: "Silver" },
];

const categories = [
  { value: "All", label: "All" },
  { value: "Home Supplies", label: "Home Supplies" },
  { value: "Kitchenware", label: "Kitchenware" },
  { value: "Household Appliances", label: "Household Appliances" },
  { value: "Womenswears", label: "Womenswears" },
  { value: "Menswear", label: "Menswear" },
  { value: "Beauty & Personal Care", label: "Beauty & Personal Care" },
  { value: "Electronics", label: "Electronics" },
  { value: "Pet Supplies", label: "Pet Supplies" },
  { value: "Sports & Outdoors", label: "Sports & Outdoors" },
  { value: "Toys & Hobbies", label: "Toys & Hobbies" },
  { value: "Furniture", label: "Furniture" },
  { value: "Health", label: "Health" },
  { value: "Automotive", label: "Automotive" },
  { value: "Fashion", label: "Fashion" },
  { value: "Bags", label: "Bags" },
  { value: "Jewelry", label: "Jewelry" },
  { value: "Food & Beverage", label: "Food & Beverage" },
];

const Marketplace = () => {
  const [selectedTab, setSelectedTab] = useState("brand");
  const [selectedCardData, setSelectedCardData] = useState();
  const [showLoader, setShowLoader] = useState(false);
  const [marketplaceData, setMarketplaceData] = useState([]);
  const globalStates = useContext(globalContext);
  const dataSection = useRef()
  
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [searchKey, setSearchKey] = useState("");
  const [page, setPage] = useState(1);

  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");

  useEffect(() => {
    if (!token) {
      navigate("/login",{replace:true});
      console.log("No token");
    } else {
      if (token) {
        // alert(role)
     
        console.log("token");
        globalStates.setIsLoggedIn(true);
   
      } else if (!token) {
        globalStates.setIsLoggedIn(false);
      }
    }
  }, [role]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (token) {
      if(role=="brand"){
        setSelectedTab("creator")
      
        getMarketplaceData("creator", page, selectedCategory, searchKey);
        }else{
         getMarketplaceData("brand", page, selectedCategory, searchKey);
        }
      
    }
  }, [selectedTab]);

  const getMarketplaceData = async (selectedTab,page,selectedCategory,searchKey) => {
    try {
      setShowLoader(true);
      const response = await apiService.getMarketplaceData(selectedTab,page,selectedCategory,searchKey);
      setShowLoader(false);
      setMarketplaceData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleTabChange = async (tab) => {
  
    if(tab == selectedTab){
      return
    }
    setSelectedTab(tab);
    setSearchKey("");
    setPage(1);
    getMarketplaceData(tab, 1, selectedCategory, "");
  };

  const handlePageChange = async (pageClicked) => {
    if(pageClicked === page){
      return
    }
    setPage(pageClicked);
    await getMarketplaceData(selectedTab, pageClicked, selectedCategory, searchKey);
    dataSection.current?.scrollIntoView({ behavior: "instant" })
  };

  const handleCategoryChange = async (category) => {
    setPage(1);
    setSelectedCategory(category);
    getMarketplaceData(selectedTab, 1, category, searchKey);
  };

  const handleChangeSearch = async (e) => {
    setSearchKey(e.target.value);
      setPage(1);
      getMarketplaceData(selectedTab, 1, selectedCategory, e.target.value);
  };

  const handleSearchClick = async () => {
    if(searchKey){
      setPage(1);
      getMarketplaceData(selectedTab, 1, selectedCategory, searchKey);
    }
  };

  const handleOnKeyEnter = async (e) => {
    if (e.key == "Enter") {
      setPage(1);
      getMarketplaceData(selectedTab, 1, selectedCategory, searchKey);
    }
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      {/* Marketplace section start */}
      <Loader showLoader={showLoader} />
      <section className="marketplace-section">
        <Container>
          <h2>Marketplace</h2>
        </Container>
      </section>
      <section ref={dataSection} className="filter-box">
        <Container>
 
          <div className="filter-box-inner">
            <Tabs
              defaultActiveKey={role=="creator"?"brand":"creator"}
              id="uncontrolled-tab-example"
              className="mb-3"
              onSelect={(key) => handleTabChange(key)}
            >
              {role=="creator"?
              <Tab eventKey="brand" title="Brand">
            
                <MarketPlaceChnageTab
                  tabType="brand"
                  options={options}
                  categories={categories}
                  selectedCategory={selectedCategory}
                  searchKey={searchKey}
                  setSearchKey={setSearchKey}
                  setSelectedCategory={setSelectedCategory}
                  handleCategoryChange={handleCategoryChange}
                  handleSearchClick={handleSearchClick}
                  handleChangeSearch={handleChangeSearch}
                  handleOnKeyEnter={handleOnKeyEnter}
                />
              </Tab>:
              <Tab eventKey="creator" title="Creator">
                <MarketPlaceChnageTab
                  tabType="creator"
                  options={options}
                  categories={categories}
                  selectedCategory={selectedCategory}
                  setSelectedCategory={setSelectedCategory}
                  handleCategoryChange={handleCategoryChange}
                  searchKey={searchKey}
                  setSearchKey={setSearchKey}
                  handleSearchClick={handleSearchClick}
                  handleChangeSearch={handleChangeSearch}
                  handleOnKeyEnter={handleOnKeyEnter}
                />
              </Tab>}
            </Tabs>
          </div>
        </Container>
      </section>
      <section className="marketplace-listing">
        <Container>
          <Row>
            {marketplaceData?.list?.length > 0 ? (
              marketplaceData?.list?.map((data) => {
                return (
                  <MarketPlaceDataCard
                    setSelectedCardData={setSelectedCardData}
                    handleShow={handleShow}
                    key={data._id}
                    data={data}
                  />
                )
              })):
              <div className="no-data-found-section">
                 <img src={require('../assets/images/no-data.gif')} alt="Left image || Brandfluence" className="" />
                 <h4>Oops! No data found!</h4>
              </div>
              }
          </Row>
        </Container>
      </section>
      {/* Marketplace section end */}

      {/* modal */}

      {selectedCardData && show ? (
        <MarketPlaceDataDetailModal
          data={selectedCardData}
          show={show}
          handleClose={handleClose}
        />
      ) : (
        <></>
      )}
      <Container>
        <div className="d-flex justify-content-end mb-4">
          {/* <MarketPlacePagination totalPage={totalPage} /> */}
          <Pagination
            activePage={page}
            itemsCountPerPage={12}
            totalItemsCount={marketplaceData?.totalUsers > 12 ? marketplaceData?.totalUsers :0}
            pageRangeDisplayed={5}
            onChange={(e) => handlePageChange(e)}
            hideDisabled={true}
            prevPageText={"Prev"}
            nextPageText={"Next"}
          />
        </div>
      </Container>
    </>
  );
};
export default Marketplace;
