import React, { useEffect } from "react";
import { Col } from "react-bootstrap";
import { config } from "../config/config";

function MarketPlaceDataCard({data,handleShow,setSelectedCardData}) {
 
    const handleClick = () =>{
        handleShow(); 
        setSelectedCardData(data)
        console.log(data)
    }
    
    const showCategory = (data) => {
      let catLength 
        if(data){
            let categories
        if(data.length == 1){
            categories = data[0].split(',')
        }else{
            categories = data
        }
        catLength = categories.length
        categories = categories.slice(0,1)
        if(catLength > 1){
          return (categories.join(', ')+` +${catLength - 1}`)
        }
        else{
          return (categories.join(', '))
        }
        }
    }
  return (
    <Col sm={12} md={6} lg={4}>
      <div className="marketplace-box" onClick={handleClick}>
        <img src={data.image? `${config.imageUrl}${data.image}` : require("../assets/images/qb-image.png")} alt="img" />
        <div className="marketplace-content">
          <h6>{data?.brandName? data.brandName : `${data.firstName} ${data.lastName}`}</h6>
          {/* <p><img src={require("../assets/images/email-icon.svg").default} />Email: {data.email}</p> */}
          {data.preferredNiches[0] && <p><img src={require("../assets/images/category.svg").default} />Categories: {showCategory(data.preferredNiches)}</p>}
        </div>
      </div>
      
    </Col>
  );
}

export default MarketPlaceDataCard;
