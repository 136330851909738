import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { apiService } from "../services/apiservices";
import { config } from "../config/config";
import Loader from "../common/Loader";
import { globalContext } from "../GlobalState/globalContext";
import instagramIcon from "../assets/images/instagramIcon.png"
import facebookLogo from "../assets/images/facebookIcon.png"
import twitterLogo from "../assets/images/logos.png"
import linkedinLogo from "../assets/images/linkedin (1).png"
import youtubeLogo from "../assets/images/youtubeIcon.png"
import tiktokLogo from "../assets/images/tiktok.png"
import pinterestLogo from "../assets/images/pinterest.png"
import redditLogo from "../assets/images/reddit (1).png"
import snapchatLogo from "../assets/images/snapChatIcon.png"



const CreatorProfile = () => {
  const [profileData, setProfileData] = useState();
  const [showLoader, setShowLoader] = useState(false);
  const navigate = useNavigate();
  const globalStates = useContext(globalContext);
  const token = localStorage.getItem("token");
  console.log(globalStates.loggedInUser)

  useEffect(() => {
    if (!token) {
      navigate("/login",{replace:true});
    } else {
      if (token) {
        globalStates.setIsLoggedIn(true);
        window.scrollTo(0, 0);
      } else if (!token) {
        globalStates.setIsLoggedIn(false);
      }
    }
  }, []);

  useEffect(() => {
    
  }, []);

  useEffect(() => {
    getProfile();
  }, [globalStates.isLoggedIn]);

  const getProfile = async () => {
    try {
      if (globalStates.setIsLoggedIn) {
        setShowLoader(true);
        const response = await apiService.getProfileData();
        setShowLoader(false);
        setProfileData(response.data.user);
      }
    } catch (error) {}
  };

  const showCategory = (data) => {
    if (data) {
      let categories;
      if (data.length == 1) {
        categories = data[0].split(",");
      } else {
        categories = data;
      }
      return categories;
    }
  };

  const validateSocialLink = (url) => {
    const patterns = {
      instagram: /instagram\.com/i,
      facebook: /facebook\.com/i,
      twitter: /twitter\.com/i,
      linkedin: /linkedin\.com/i,
      youtube: /(youtube\.com|youtu\.be)/i,
      tiktok: /tiktok\.com/i,
      pinterest: /pinterest\.com/i,
      reddit: /reddit\.com/i,
      snapchat: /snapchat\.com/i,
    };
  
    const images = {
      instagram: instagramIcon,
      facebook: facebookLogo,
      twitter: twitterLogo,
      linkedin: linkedinLogo,
      youtube: youtubeLogo,
      tiktok: tiktokLogo,
      pinterest: pinterestLogo,
      reddit: redditLogo,
      snapchat: snapchatLogo,
    };
  
    // Normalize the URL by adding protocol if missing
    const normalizeUrl = (inputUrl) => {
      if (!/^https?:\/\//i.test(inputUrl)) {
        return `https://${inputUrl}`;
      }
      return inputUrl;
    };
  
    const normalizedUrl = normalizeUrl(url);
  
    // Match the URL to a platform
    for (const [platform, pattern] of Object.entries(patterns)) {
      if (pattern.test(normalizedUrl)) {
        return (
          <li>
            <a href={normalizedUrl} target="_blank" rel="noopener noreferrer">
              <img title={platform} className="social-media-icon" src={images[platform]} alt={`${platform} logo`} />
            </a>
          </li>
        );
      }
    }
  
    // Default case for unknown platforms
    return (
      <li>
        <a href={normalizedUrl} target="_blank" rel="noopener noreferrer">
          <i title="Social link" className="bi bi-link-45deg"></i>
        </a>
      </li>
    );
  };


  return (
    <>
      <Loader showLoader={showLoader} />
      <section className="creater-profile-outer">
        <section className="creater-profile">
          <Container>
            <h2>Profile</h2>
          </Container>
        </section>
        {profileData && (
          <section className="craeter-profile-content">
            <Container>
              <Row>
                <Col md={12}>
                  <div className="creater-content">
                    <div className="creater-img">
                      <img
                        src={
                          profileData.image
                            ? `${config.imageUrl}${profileData.image}`
                            : require("../assets/images/qb-image.png")
                        }
                      />
                    </div>
                    <div className="creater-detail">
                      <div className="creater-detail-top">
                        <div className="creater-detail-top-left">
                          <h6>
                            {profileData?.brandName
                              ? profileData.brandName
                              : `${profileData.firstName} ${profileData.lastName}`}
                          </h6>
                          {/* <span>Makeup Artist</span> */}
                          <ul>
                            {
                                profileData.socialLinks && profileData.socialLinks?.map((link,index)=>{
                                  if(link != ""){
                                    return (<React.Fragment key={index} >
                                      {validateSocialLink(link)}
                                  </React.Fragment>
                                  )
                                  }
                                    
                                })
                            }
                            {/* <li>
                              <img
                                src={
                                  require("../assets/images/instagram.svg")
                                    .default
                                }
                              />
                            </li>
                            <li>
                              <img
                                src={
                                  require("../assets/images/tiktok.svg").default
                                }
                              />
                            </li>
                            <li>
                              <img
                                src={
                                  require("../assets/images/youtube-icon.svg")
                                    .default
                                }
                              />
                            </li> */}
                          </ul>
                        </div>
                        <svg
                          onClick={() => {
                            navigate("/update-profile");
                          }}
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M17.5 18.333H2.5C2.15833 18.333 1.875 18.0497 1.875 17.708C1.875 17.3663 2.15833 17.083 2.5 17.083H17.5C17.8417 17.083 18.125 17.3663 18.125 17.708C18.125 18.0497 17.8417 18.333 17.5 18.333Z"
                            fill="#1037FD"
                          />
                          <path
                            d="M15.8495 2.90005C14.2328 1.28338 12.6495 1.24172 10.9912 2.90005L9.98283 3.90838C9.89949 3.99172 9.86616 4.12505 9.89949 4.24172C10.5328 6.45005 12.2995 8.21672 14.5078 8.85005C14.5412 8.85838 14.5745 8.86672 14.6078 8.86672C14.6995 8.86672 14.7828 8.83338 14.8495 8.76672L15.8495 7.75838C16.6745 6.94172 17.0745 6.15005 17.0745 5.35005C17.0828 4.52505 16.6828 3.72505 15.8495 2.90005Z"
                            fill="#1037FD"
                          />
                          <path
                            d="M13.007 9.60768C12.7653 9.49101 12.532 9.37435 12.307 9.24102C12.1236 9.13268 11.9486 9.01602 11.7736 8.89102C11.632 8.79935 11.4653 8.66602 11.307 8.53268C11.2903 8.52435 11.232 8.47435 11.1653 8.40768C10.8903 8.17435 10.582 7.87435 10.307 7.54102C10.282 7.52435 10.2403 7.46602 10.182 7.39102C10.0986 7.29102 9.95697 7.12435 9.83197 6.93268C9.73197 6.80768 9.6153 6.62435 9.50697 6.44102C9.37364 6.21602 9.25697 5.99102 9.1403 5.75768C8.98734 5.4299 8.55713 5.33253 8.30135 5.5883L3.6153 10.2743C3.50697 10.3827 3.40697 10.591 3.38197 10.7327L2.93197 13.9243C2.84864 14.491 3.00697 15.0243 3.35697 15.3827C3.65697 15.6743 4.07364 15.8327 4.52364 15.8327C4.62364 15.8327 4.72364 15.8243 4.82364 15.8077L8.02364 15.3577C8.17364 15.3327 8.38197 15.2327 8.48197 15.1243L13.1758 10.4306C13.4264 10.1799 13.3322 9.74863 13.007 9.60768Z"
                            fill="#1037FD"
                          />
                        </svg>
                      </div>
                      <p style={{ whiteSpace: "pre-wrap" }}>
                        {profileData?.description ? profileData?.description : <span className="text-muted">No description</span>}
                      </p>
                      <ul className="creater-info">
                        {profileData?.phoneNumber && (
                          <li>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                            >
                              <path
                                d="M14.6846 8.95866C14.3263 8.95866 14.043 8.66699 14.043 8.31699C14.043 8.00866 13.7346 7.36699 13.218 6.80866C12.7096 6.26699 12.1513 5.95033 11.6846 5.95033C11.3263 5.95033 11.043 5.65866 11.043 5.30866C11.043 4.95866 11.3346 4.66699 11.6846 4.66699C12.518 4.66699 13.393 5.11699 14.1596 5.92533C14.8763 6.68366 15.3346 7.62533 15.3346 8.30866C15.3346 8.66699 15.043 8.95866 14.6846 8.95866Z"
                                fill="#1037FD"
                              />
                              <path
                                d="M17.6924 8.95866C17.3341 8.95866 17.0508 8.66699 17.0508 8.31699C17.0508 5.35866 14.6424 2.95866 11.6924 2.95866C11.3341 2.95866 11.0508 2.66699 11.0508 2.31699C11.0508 1.96699 11.3341 1.66699 11.6841 1.66699C15.3508 1.66699 18.3341 4.65033 18.3341 8.31699C18.3341 8.66699 18.0424 8.95866 17.6924 8.95866Z"
                                fill="#1037FD"
                              />
                              <path
                                d="M9.20964 12.4587L7.66797 14.0003C7.34297 14.3253 6.8263 14.3253 6.49297 14.0087C6.4013 13.917 6.30964 13.8337 6.21797 13.742C5.35964 12.8753 4.58464 11.967 3.89297 11.017C3.20964 10.067 2.65964 9.11699 2.25964 8.17533C1.86797 7.22533 1.66797 6.31699 1.66797 5.45033C1.66797 4.88366 1.76797 4.34199 1.96797 3.84199C2.16797 3.33366 2.48464 2.86699 2.9263 2.45033C3.45964 1.92533 4.04297 1.66699 4.65964 1.66699C4.89297 1.66699 5.1263 1.71699 5.33464 1.81699C5.5513 1.91699 5.74297 2.06699 5.89297 2.28366L7.8263 5.00866C7.9763 5.21699 8.08464 5.40866 8.15964 5.59199C8.23464 5.76699 8.2763 5.94199 8.2763 6.10033C8.2763 6.30033 8.21797 6.50033 8.1013 6.69199C7.99297 6.88366 7.83464 7.08366 7.63463 7.28366L7.0013 7.94199C6.90964 8.03366 6.86797 8.14199 6.86797 8.27533C6.86797 8.34199 6.8763 8.40033 6.89297 8.46699C6.91797 8.53366 6.94297 8.58366 6.95964 8.63366C7.10964 8.90866 7.36797 9.26699 7.73463 9.70033C8.10963 10.1337 8.50963 10.5753 8.94297 11.017C9.0263 11.1003 9.11797 11.1837 9.2013 11.267C9.53464 11.592 9.54297 12.1253 9.20964 12.4587Z"
                                fill="#1037FD"
                              />
                              <path
                                d="M18.3083 15.2752C18.3083 15.5085 18.2667 15.7502 18.1833 15.9835C18.1583 16.0502 18.1333 16.1169 18.1 16.1835C17.9583 16.4835 17.775 16.7669 17.5333 17.0335C17.125 17.4835 16.675 17.8085 16.1667 18.0169C16.1583 18.0169 16.15 18.0252 16.1417 18.0252C15.65 18.2252 15.1167 18.3335 14.5417 18.3335C13.6917 18.3335 12.7833 18.1335 11.825 17.7252C10.8667 17.3169 9.90833 16.7669 8.95833 16.0752C8.63333 15.8335 8.30833 15.5919 8 15.3335L10.725 12.6085C10.9583 12.7835 11.1667 12.9169 11.3417 13.0085C11.3833 13.0252 11.4333 13.0502 11.4917 13.0752C11.5583 13.1002 11.625 13.1085 11.7 13.1085C11.8417 13.1085 11.95 13.0585 12.0417 12.9669L12.675 12.3419C12.8833 12.1335 13.0833 11.9752 13.275 11.8752C13.4667 11.7585 13.6583 11.7002 13.8667 11.7002C14.025 11.7002 14.1917 11.7335 14.375 11.8085C14.5583 11.8835 14.75 11.9919 14.9583 12.1335L17.7167 14.0919C17.9333 14.2419 18.0833 14.4169 18.175 14.6252C18.2583 14.8335 18.3083 15.0419 18.3083 15.2752Z"
                                fill="#1037FD"
                              />
                            </svg>
                            Phone: {profileData?.phoneNumber}
                          </li>
                        )}
                        <li>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M14.1641 2.91602H5.83073C3.33073 2.91602 1.66406 4.16602 1.66406 7.08268V12.916C1.66406 15.8327 3.33073 17.0827 5.83073 17.0827H14.1641C16.6641 17.0827 18.3307 15.8327 18.3307 12.916V7.08268C18.3307 4.16602 16.6641 2.91602 14.1641 2.91602ZM14.5557 7.99102L11.9474 10.0743C11.3974 10.516 10.6974 10.7327 9.9974 10.7327C9.2974 10.7327 8.58906 10.516 8.0474 10.0743L5.43906 7.99102C5.1724 7.77435 5.13073 7.37435 5.33906 7.10768C5.55573 6.84102 5.9474 6.79102 6.21406 7.00768L8.8224 9.09102C9.45573 9.59935 10.5307 9.59935 11.1641 9.09102L13.7724 7.00768C14.0391 6.79102 14.4391 6.83268 14.6474 7.10768C14.8641 7.37435 14.8224 7.77435 14.5557 7.99102Z"
                              fill="#1037FD"
                            />
                          </svg>
                          Email: {profileData.email}
                        </li>
                      </ul>
                     
                        
                          {" "}
                          <h5>Industries Work for:</h5>
                          <div className="work-industries">
                            {showCategory(profileData.preferredNiches).map(
                              (category, index) => {
                                return <p key={index}>{category}</p>;
                              }
                            )}
                          </div>
                        
                      
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        )}
      </section>
    </>
  );
};
export default CreatorProfile;
