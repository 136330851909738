import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Loader from "../common/Loader";
const ContactUs = () => {
   useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* <Loader showLoader={showLoader} /> */}
      <section className="creater-profile-outer">
        <section className="creater-profile">
          <Container>
            <h2>Get in touch</h2>
          </Container>
        </section>
        <section className="craeter-profile-content">
          <Container>
            <div className="">
              <Row className="justify-content-center">
                <Col md={6}>
                  <div className="contact-box">
                    <img
                      src={require("../assets/images/call-icon-main.svg").default}
                      alt="Left image || Brandfluence"
                      className="phone-icon"
                    />
                    <h6>Phone</h6>
                    <p>1 (948) 888-0313</p>
                    <a href="tel:1 (948) 888-0313">1 (948) 888-0313</a>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="contact-box">
                    <img
                      src={require("../assets/images/email-icon-main.svg").default}
                      alt="Left image || Brandfluence"
                      className="phone-icon"
                    />
                    <h6>Email id</h6>
                    <p>Interested in collaborating or partnering with us? Drop us an email at the provided address.</p>
                    <a href="mailto:info@brand-fluence.com">info@brand-fluence.com</a>
                  </div>
                </Col>
                {/* <Col md={6} className="mt-4">
                  <div className="contact-box">
                    <img
                      src={require("../assets/images/location.svg").default}
                      alt="Left image || Brandfluence"
                      className="phone-icon"
                    />
                    <h6>Location</h6>
                    <p>Interested in collaborating or partnering with us? Stop by our office for more details!</p>
                    <a href="">Tufts International Center, 20 Sawyer Ave, 
                    Medford MA 02155 </a>
                  </div>
                </Col> */}
              </Row>
            </div>
          </Container>
        </section>
      </section>
    </>
  );
};
export default ContactUs;
