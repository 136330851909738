import { useContext, useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Tab, Tabs } from "react-bootstrap";
import { globalContext } from "../GlobalState/globalContext";
import { useLocation, useNavigate } from "react-router-dom";
import { apiService } from "../services/apiservices";
import Swal from "sweetalert2";
import Loader from "../common/Loader";

const ForgotPassword = () => {
  const location = useLocation();
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("creator");
  const [errors, setErrors] = useState();
  const [showLoader, setShowLoader] = useState(false);
  const navigate = useNavigate();
  const globalStates = useContext(globalContext);
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (localStorage.getItem("token")) {
      navigate('/');
    }
  }, []);
  const handleSelect = (key) => {
    console.log(`Active tab: ${key}`);
    setRole(key); // Update the active tab key
  };
  useEffect(() => {
    if (location?.state?.email) {
      setEmail(location?.state?.email);
    }
  }, [location?.state?.email]);

  const validateInput = (name, value) => {
    let error = "";
    switch (name) {
      case "email":
        if (!value.trim()) {
          error = "Email is required.";
        } else if (
          !/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/.test(value)
        ) {
          error = "Invalid email";
        }
        break;
      default:
        break;
    }
    return error;
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEmail(value);
    const error = validateInput(name, value);
    setErrors(error);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!errors) {
      const params = {
        email: email,
        role:role
      };
      try {
        setShowLoader(true);
        const response = await apiService.getForgotPasswordEmail(params);
        if (response.status >= 200 && response.status <= 299) {
          setShowLoader(false);
          Swal.fire({
            title: "Email sent!",
            text: "A reset password link has been sent to your email",
            icon: "success",
          });
          setEmail("");
        }
      } catch (error) {
        console.log(error);
        setShowLoader(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text:
            error?.response?.data?.message ||
            "Something went wrong, please try again.",
        });
      }
    }
  };

  return (
    <>
      {/* Page start */}
      {<Loader showLoader={showLoader} />}
      <section className="login-section">
        <Container fluid>
          <Row className="align-items-center">
            <Col md={6}>
              <div className="left-side-img">
                <img
                  src={require("../assets/images/forgot-img.png")}
                  alt="Left image || Brandfluence"
                  className="w-100"
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="right-side-form">
                <img
                  onClick={() => {
                    navigate(-1);
                  }}
                  src={require("../assets/images/back-icon.svg").default}
                  alt="Logo || Brandfluence"
                  className="back-btn d-block"
                />
                <img
                  onClick={() => { navigate("/") }}
                  src={require("../assets/images/logo-icon.png")}
                  alt="Logo || Brandfluence"
                  className=""
                />
                <h1>Forgot Password</h1>
                <Tabs
                  defaultActiveKey="creator"
                  id="uncontrolled-tab-example"
                  className=""
                  onSelect={handleSelect}
                >
                  <Tab eventKey="creator" title="As Creator">
                    <Form className="forgot-form">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Enter your email"
                          name="email"
                          value={email}
                          onChange={handleInputChange}
                        />
                        {errors && <span className="text-danger">{errors}</span>}
                      </Form.Group>
                      <Button
                        variant="primary"
                        onClick={handleSubmit}
                        className="submit-btn"
                      >
                        Submit
                      </Button>
                    </Form>
                  </Tab>
                  <Tab eventKey="brand" title="As Brand">
                    <Form className="forgot-form">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Enter your email"
                          name="email"
                          value={email}
                          onChange={handleInputChange}
                        />
                        {errors && <span className="text-danger">{errors}</span>}
                      </Form.Group>
                      <Button
                        variant="primary"
                        onClick={handleSubmit}
                        className="submit-btn"
                      >
                        Submit
                      </Button>
                    </Form>
                  </Tab>
                </Tabs>

              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Page end */}
    </>
  );
};
export default ForgotPassword;
