import { useContext, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useNavigate, useParams } from "react-router-dom";
import { globalContext } from "../GlobalState/globalContext";
import { apiService } from "../services/apiservices";
import Swal from "sweetalert2";
import Loader from "../common/Loader";

const GeneratePassword = () => {
  const [show, setShow] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const { token,role } = useParams();
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  });
  const globalStates = useContext(globalContext);

  const navigate = useNavigate();
  const handleClose = () => {
    setShow(false)
    navigate('/login')
  };
  const [data, setData] = useState({
    password: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({});
  const validateInput = (name, value) => {
    let error = "";

    switch (name) {
      case "password":
        const trimmedValue = value.trim();
        if (!trimmedValue) {
          error = "Password is required.";
        } else if (trimmedValue.length < 8) {
          error = "Password must be at least 8 characters long.";
        } else if (
          !/^(?=.*[A-Za-z])(?=.*\d)(?=.*[`~!@#$%^&*()\-_+={[}\|:;"'<,>.?/]).*$/.test(
            trimmedValue
          )
        ) {
          error =
            "Password must include at least 1 letter, 1 number, and 1 special character.";
        }
        break;
      case "confirmPassword":
        const trimmedpass = value.trim();
        if (trimmedpass !== data.password) {
          error = "Confirm password does not match the password";
        }
        break;
      default:
        break;
    }
    return error;
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData((prev) => ({ ...prev, [name]: value }));
    const error = validateInput(name, value);
    setErrors({ ...errors, [name]: error });
    if(name == "password"){
      if(value === data.confirmPassword){
        setErrors({...errors,confirmPassword:""})
      }
      else if(value !== data.confirmPassword && data.confirmPassword){
        setErrors({...errors,confirmPassword:"Confirm password does not match the password"})
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(errors);
    if(data.password !== data.confirmPassword){
      setErrors({...errors,confirmPassword:"Confirm password does not match the password"})
      return
    }
    if (data.password === data.confirmPassword) {
      setErrors({...errors,password:"",confirmPassword:""})
      const params = {
        newPassword: data.password,
      };
      try {
        setShowLoader(true);
        const response = await apiService.resetPasswordOtp(token, params);
        if (response.status >= 200 && response.status <= 299) {
          setShow(true);
          setData({ ...data, password: "", confirmPassword: "" });
        }
        setShowLoader(false);
      } catch (error) {
        console.log(error);
        setShowLoader(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text:
            error?.response?.data?.message ||
            "Something went wrong, please try again.",
        });
      }
    }
  };

  return (
    <>
      {/* Page start */}
      {<Loader showLoader={showLoader} />}
      <section className="login-section">
        <Container fluid>
          <Row className="align-items-center">
            <Col md={6}>
              <div className="left-side-img">
                <img
                  src={require("../assets/images/forgot-img.png")}
                  alt="Left image || Brandfluence"
                  className="w-100"
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="right-side-form">
                {/* <img
                  onClick={() => {
                    navigate(-1);
                  }}
                  src={require("../assets/images/back-icon.svg").default}
                  alt="Logo || Brandfluence"
                  className="back-btn d-block"
                /> */}
                <img
                  src={require("../assets/images/logo-icon.png")}
                  alt="Logo || Brandfluence"
                  className=""
                  onClick={()=>{navigate("/")}}
                />
                <h1>Create New Password</h1>
                <Form className="forgot-form">
                  <Form.Group
                    className="mb-3 position-relative"
                    controlId="formBasicPassword"
                  >
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      autoComplete="new-password"
                      type={showPassword.password ? "text" : "password"}
                      placeholder="Enter your password"
                      name="password"
                      value={data.password}
                      onChange={handleInputChange}
                    />
                    {errors.password && (
                      <span className="text-danger">{errors.password}</span>
                    )}
                    <img
                      onClick={() => {
                        setShowPassword({
                          ...showPassword,
                          password: !showPassword.password,
                        });
                      }}
                      src={
                        showPassword.password
                          ? require("../assets/images/view-eye.svg").default
                          : require("../assets/images/eye-open.svg").default
                      }
                      alt="Logo || Brandfluence"
                      className="eye-icon"
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3 position-relative"
                    controlId="formBasicPassword"
                  >
                    <Form.Label>Confirm password</Form.Label>
                    <Form.Control
                      autoComplete="new-password"
                      type={showPassword.confirmPassword ? "text" : "password"}
                      placeholder="Confirm your password"
                      name="confirmPassword"
                      value={data.confirmPassword}
                      onChange={handleInputChange}
                    />
                    {errors.confirmPassword && (
                      <span className="text-danger">
                        {errors.confirmPassword}
                      </span>
                    )}
                    <img
                      onClick={() => {
                        setShowPassword({
                          ...showPassword,
                          confirmPassword: !showPassword.confirmPassword,
                        });
                      }}
                      src={
                        showPassword.confirmPassword
                          ? require("../assets/images/view-eye.svg").default
                          : require("../assets/images/eye-open.svg").default
                      }
                      alt="Logo || Brandfluence"
                      className="eye-icon"
                    />
                  </Form.Group>
                  <Button
                    variant="primary"
                    className="submit-btn"
                    onClick={handleSubmit}
                  >
                    Reset Password
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* success modal start */}
      <Modal
        show={show}
        onHide={handleClose}
        className="confirmation-outer-modal"
        centered
      >
        <Modal.Header
          closeButton
          className="border-0 close-btns"
        ></Modal.Header>
        <Modal.Body>
          <div className="confirmation-details text-center">
            <img
              src={require("../assets/images/check-icon.png")}
              alt="Logo || Brandfluence"
              className=""
            />
            <h2>Password Successfully Updated!</h2>
            <p>
              Your password has been successfully updated. You can now log in
              with your new credentials.
            </p>
          </div>
        </Modal.Body>
      </Modal>
      {/* success modal end */}
      {/* Page end */}
    </>
  );
};
export default GeneratePassword;
